<script>
    export default{
        name: "StrGlowna",
        data() {
            return { 
                klasa: "col-sm-6 col-md-4 col-lg-3 mb-3",
            }
        },
        components:{

        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <div class="row">
            <div  class="col-sm-0 col-md-1 col-lg-1">
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3 mb-3">
                <div align="center">
                    <b-avatar src="./images/zdjecie.jpg"  size="12rem"></b-avatar>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-7 mb-3 d-flex align-items-center">
                <div class="mx-auto">
                    <h3 class="font-italic" align="center">jutrzejsza innowacja to synteza wczorajszego pomysłu i dzisiejszej technologii</h3>
                    <h5 class="font-italic" align="right">Adam Bartoszewicz</h5>
                </div>
            </div>
            <div  class="col-sm-0 col-md-1 col-lg-1">
            </div>
        </div>
        <br>
        <h5 align="center">Zajmuję się projektowaniem urządzeń elektronicznych, mechanicznych i tworzeniem oprogramowania. W projektach wykorzystuję również Sztuczną Inteligencję.</h5>
        <br>
        <h5 align="center">Zapraszam Państwa do działu Doświadczenie w celu zapoznania się z moimi możliwościami projektowymi.</h5>
        <br>
        <h5 align="center">Warto również odwiedzić dział Blog. Znajdą tam Państwo wiele materiałów pomocnych podczas podejmowania decyzji dotyczących przyszłego projektu.</h5>
        <br>

    </div>
</template>